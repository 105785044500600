<template>
    <div class="shows">
        <div class="shows-options">
            <div class="option" @click="modle='ShowMain'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול תצוגות</p>
            </div>
        </div>
        <div v-if="modle" class="shows-panel">
            <keep-alive>
                <component :is="modle"/>
            </keep-alive>
        </div>  
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import ShowMain from '../../components/Admin/Shows/ShowMain.vue'
export default {
components:{ShowMain},
setup(){
    const modle = ref(null)
   
    return{ 
        modle,
    }
}
}
</script>

<style scoped>
.shows{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 350px calc(100% - 350px);
    overflow-y: auto;
}
.shows-options{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 50px;
    gap: 8px;
    overflow-y: auto;
}
.option{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 26px;
    background: var(--secondary);
    padding: 0 5px 0 0;
    border-radius: 5px;
    cursor: pointer;
}
.option:hover p{
    margin-right:25px;
    color: var(--yellow);
}
.option i {
    margin-left: 25px;
    color: var(--light);
    font-size: 33px;
}
.option p{
    color:var(--light);
    transition: 0.25s;
}
.shows-panel{
    width: 100%;
    height: 100%;
    padding: 0 5px 0 5px;
}

@media only screen and (max-width: 660px) {
    .shows{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 180px 600px;
        grid-template-columns: 100%;
    }
    .shows-panel{
        height: 100%;
        padding: 0 0 0 0;
    }
    ::-webkit-scrollbar{
        width: 0;
    }
}
</style>